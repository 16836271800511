import { Navigate, Route, Routes } from "react-router-dom";
import { lazy } from "react";
import { useSelector } from "react-redux";

import { AppLoader } from "../splash";
const Main = lazy(() => import("../page/Step/Main"));
const Vote = lazy(() => import("../page/Vote"));
const ChooseType = lazy(() => import("../page/Vote/Answer"));

// const Main = lazy(() => import("../Layout/Main"));
// const LoginPage = lazy(() => import("../Pages/LoginPage"));

export const Router = ({ mode, changeMode }) => {
  // const { isLoading: isAuthLoading } = useSelector((s) => s?.auth);

  // if (isAuthLoading) return <AppLoader />;

  return (
    <Routes>
      <Route path="/Main" element={<Main />} />
      <Route path="/Vote/:type" element={<Vote />} />
      <Route path="/*" element={<ChooseType />} />
      

      {/* <Route
        path="login"
        element={<SuspenseComponent>{<LoginPage />}</SuspenseComponent>}
      />
      <Route path="/*" element={<Main />} /> */}

      {/* <Route
        path="404"
        element={/* <NotFound /> 
      />
      <Route path="*" element={<Navigate to="/404" />} /> */}
    </Routes>
  );
};

export default Router;
